<template>
  <v-app>
    <div v-if="this.signedIn" class="viewWordsContent">

      <div id="nav">
        <router-link id="backToAdmin" to="/admin">&larr; back to Admin</router-link> |
        <router-link to="/nouns">Nouns</router-link> |
        <router-link to="/sentencesnouns">Sentences &mdash; Nouns</router-link> |
        <router-link to="/adjectives">Adjectives</router-link> |
        <router-link to="/sentencesadjectives">Sentences &mdash; Adjectives</router-link>
      </div>


      <v-expansion-panels>
        <v-expansion-panel style="margin: 10px 0">
          <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
            <h3>Instructions</h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2">
            <div style="text-align:center;margin:15px 0 10px 0; padding:20px;border: 2px solid #343a40; border-radius:20px;">
              <p>Type the English translation, and the stem of the adjective (e.g. дорог for дорогой)</p>
              <p>The algorithm will auto-complete the declensions that it is possible to complete programmatically.</p>
              <p>Complete the remaining fields manually (incomplete / invalid fields are coloured pink).</p>
              <hr style="border: 1px solid rgba(0,0,0,0.5);width:30%;margin:auto auto 10px auto">
              <p>accI = accusative inanimate; accA = accusative animate</p>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>



      <h1>Add Adjectives</h1>

      <v-card style="border-color: #fff;box-shadow: none; text-align:center">
        <v-row style="margin:0;padding:0">
          <v-col tile cols="1.5" v-for="i in docItems" :key="i">
            <v-chip label color="#8ecae6">{{i}}</v-chip>
          </v-col>
          <v-col cols="0.5">
          </v-col>
        </v-row>
      </v-card>

      <v-card style="border-color: #fff;box-shadow: none; text-align:center">
        <v-row hide-details class="d-flex pa-0 ma-1">
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" ref="adjEnglishRef" v-model="english" class="ma-0 pa-0 text-english"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined v-model="stem" @keyup="updateDeclensions('this')" class="ma-0 pa-0 text-stem"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
            <v-checkbox
              v-if="this.stem.substring(this.stem.length - 1, this.stem.length) == 'н'"
              v-model="softStem"
              :label="'Soft-Stem?'"
              @change="updateDeclensions('this')"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col cols="0.5" class="d-inline-flex pa-0 ma-1">
            <v-btn x-large elevation=0 color="green lighten-3" height=56px @click="addWord()">Add</v-btn>
          </v-col>
        </v-row>

        <v-row hide-details class="d-flex pa-0 ma-1">
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-chip large label class="chipWidth" color="#8ecae6">Masculine</v-chip></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascNom, this.stem)" v-model="mascNom" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascGen, this.stem)" v-model="mascGen" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascAccI, this.stem)" v-model="mascAccI" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascAccA, this.stem)" v-model="mascAccA" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascDat, this.stem)" v-model="mascDat" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascInst, this.stem)" v-model="mascInst" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.mascPrep, this.stem)" v-model="mascPrep" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"></v-col>
        </v-row>

        <v-row hide-details class="d-flex pa-0 ma-1">
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-chip large label class="chipWidth" color="#8ecae6">Feminine</v-chip></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femNom, this.stem)" v-model="femNom" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femGen, this.stem)" v-model="femGen" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femAccI, this.stem)" v-model="femAccI" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femAccA, this.stem)" v-model="femAccA" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femDat, this.stem)" v-model="femDat" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femInst, this.stem)" v-model="femInst" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.femPrep, this.stem)" v-model="femPrep" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"></v-col>
        </v-row>

        <v-row hide-details class="d-flex pa-0 ma-1">
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-chip large label class="chipWidth" color="#8ecae6">Neuter</v-chip></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutNom, this.stem)" v-model="neutNom" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutGen, this.stem)" v-model="neutGen" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutAccI, this.stem)" v-model="neutAccI" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutAccA, this.stem)" v-model="neutAccA" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutDat, this.stem)" v-model="neutDat" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutInst, this.stem)" v-model="neutInst" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.neutPrep, this.stem)" v-model="neutPrep" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"></v-col>
        </v-row>

        <v-row hide-details class="d-flex pa-0 ma-1">
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-chip large label class="chipWidth" color="#8ecae6">Plural</v-chip></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluNom, this.stem)" v-model="pluNom" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluGen, this.stem)" v-model="pluGen" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluAccI, this.stem)" v-model="pluAccI" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluAccA, this.stem)" v-model="pluAccA" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluDat, this.stem)" v-model="pluDat" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluInst, this.stem)" v-model="pluInst" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" :class="getColourComplete(this.pluPrep, this.stem)" v-model="pluPrep" class="ma-0 pa-0"></v-text-field></v-col>
          <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"></v-col>
        </v-row>
      </v-card>





      <div style="text-align:center;margin:40px 0; padding:20px;border: 2px solid #343a40; border-radius:20px;">
        <h3>Highlight Legend</h3>
        <p style="margin:0;">How many sentences use this adjectives in this case?</p>
        <v-chip color="#000" label outlined class="ma-1">0 sentences</v-chip>
        <v-chip color="#d8f3dc" label class="ma-1">1 sentence</v-chip>
        <v-chip color="#b7e4c7" label class="ma-1">2 sentences</v-chip>
        <v-chip color="#95d5b2" label class="ma-1">3 sentences</v-chip>
        <v-chip color="#74c69d" label class="ma-1">4+ sentences</v-chip>

        <h3 style="margin-top:10px;">More Info</h3>
        <p>If a delete button is disabled, it's because there is at least one sentence using the adjective. Deleting the sentence
        could potentially cause an error -- delete the adjective from the sentences first.</p>
      </div>






      <div class="incompleteWords" v-if="IncompleteWords.length > 0">

        <h1>Incomplete Adjectives</h1>

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col tile cols="1.5" v-for="i in docItems" :key="i">
              <v-chip label color="#8ecae6">{{i}}</v-chip>
            </v-col>
            <v-col cols="1.5">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Word in IncompleteWords" :key="Word.id">

          <v-expansion-panels>
            <v-expansion-panel style="margin: 10px 0">
              <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
                <h3>{{Word.english}}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-2">

                <v-card style="border-color: #fff;box-shadow: none; text-align:center">
                  <v-row hide-details class="d-flex pa-0 ma-1" :key="componentKey">
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" ref="adjEnglishRef" v-model="Word.english" class="ma-0 pa-0 text-english"></v-text-field></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined :ref='"input_" + Word.id' v-model="Word.stem" @keyup="updateDeclensions(Word)" class="ma-0 pa-0 text-stem"></v-text-field></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                      <v-checkbox
                        v-if="Word.stem != null && Word.stem.substring(Word.stem.length - 1, Word.stem.length) == 'н'"
                        v-model="Word.softStem"
                        :label="'Soft-Stem?'"
                        @change="updateDeclensions(Word)"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col cols="1.5" class="d-inline-flex pa-0 ma-1">
                      <v-btn icon color="#e63946" :disabled="disableDeleteButton(Word)" @click="deleteWord(Word)">
                        <v-icon>
                          mdi-delete
                        </v-icon>
                      </v-btn>

                      <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(Word)">
                        <v-icon>
                          mdi-check-circle
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row hide-details class="d-flex pa-0 ma-1">
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Masculine</v-chip> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[0], Word.stem, Word.mascNomSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[1], Word.stem, Word.mascGenSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[2], Word.stem, Word.mascAccISentences.length)" outlined autocomplete="off" v-model="Word.mascForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[3], Word.stem, Word.mascAccASentences.length)" outlined autocomplete="off" v-model="Word.mascForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[4], Word.stem, Word.mascDatSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[5], Word.stem, Word.mascInstSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[6], Word.stem, Word.mascPrepSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                  </v-row>

                  <v-row hide-details class="d-flex pa-0 ma-1">
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Feminine</v-chip> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[0], Word.stem, Word.femNomSentences.length)" outlined autocomplete="off" v-model="Word.femForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[1], Word.stem, Word.femGenSentences.length)" outlined autocomplete="off" v-model="Word.femForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[2], Word.stem, Word.femAccISentences.length)" outlined autocomplete="off"  v-model="Word.femForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[3], Word.stem, Word.femAccASentences.length)" outlined autocomplete="off"  v-model="Word.femForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[4], Word.stem, Word.femDatSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[5], Word.stem, Word.femInstSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[6], Word.stem, Word.femPrepSentences.length)" outlined autocomplete="off" v-model="Word.femForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                  </v-row>

                  <v-row hide-details class="d-flex pa-0 ma-1">
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Neuter</v-chip> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[0], Word.stem, Word.neutNomSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[1], Word.stem, Word.neutGenSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[2], Word.stem, Word.neutAccISentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[3], Word.stem, Word.neutAccASentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[4], Word.stem, Word.neutDatSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[5], Word.stem, Word.neutInstSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[6], Word.stem, Word.neutPrepSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                  </v-row>

                  <v-row hide-details class="d-flex pa-0 ma-1">
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Plural</v-chip> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[0], Word.stem, Word.pluNomSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[1], Word.stem, Word.pluGenSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[2], Word.stem, Word.pluAccISentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[3], Word.stem, Word.pluAccASentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[4], Word.stem, Word.pluDatSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[5], Word.stem, Word.pluInstSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[6], Word.stem, Word.pluPrepSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                    <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                  </v-row>

                </v-card>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>

      </div>




      <v-row class="mt-16">
        <v-col cols="4">
          <h1>Complete Adjectives</h1>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="4">
          <v-text-field
              outlined
              placeholder="Search Complete Words"
              autocomplete="off"
              v-model="adjectivesSearchBar"
              @input="search()"
              :hint="searchedAdjectives.length + ' ' + this.searchedResultTag + ' found'">
          </v-text-field>
        </v-col>
      </v-row>


<!-- Search -->
      <div class="incompleteWords" v-if="searchedAdjectives.length > 0">

        <v-card style="border-color: #fff;box-shadow: none; text-align:center">
          <v-row style="margin:0;padding:0">
            <v-col tile cols="1.5" v-for="i in docItems" :key="i">
              <v-chip label color="#8ecae6">{{i}}</v-chip>
            </v-col>
            <v-col cols="1.5">
              <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
            </v-col>
          </v-row>
        </v-card>

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Word in searchedAdjectives" :key="Word.id">

            <v-expansion-panels>
              <v-expansion-panel style="margin: 10px 0">
                <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
                  <h3>{{Word.english}}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-2">

                  <v-card style="border-color: #fff;box-shadow: none; text-align:center">
                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" ref="adjEnglishRef" v-model="Word.english" class="ma-0 pa-0 text-english"></v-text-field></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined :ref='"input_" + Word.id' v-model="Word.stem" @keyup="updateDeclensions(Word)" class="ma-0 pa-0 text-stem"></v-text-field></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                        <v-checkbox
                          v-if="Word.stem != null && Word.stem.substring(Word.stem.length - 1, Word.stem.length) == 'н'"
                          v-model="Word.softStem"
                          :label="'Soft-Stem?'"
                          @change="updateDeclensions(Word)"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col cols="1.5" class="d-inline-flex pa-0 ma-1">
                        <v-btn icon color="#e63946" :disabled="disableDeleteButton(Word)" @click="deleteWord(Word)">
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>

                        <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(Word)">
                          <v-icon>
                            mdi-check-circle
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Masculine</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[0], Word.stem, Word.mascNomSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[1], Word.stem, Word.mascGenSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[2], Word.stem, Word.mascAccISentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[3], Word.stem, Word.mascAccASentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[4], Word.stem, Word.mascDatSentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[5], Word.stem, Word.mascInstSentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[6], Word.stem, Word.mascPrepSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Feminine</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[0], Word.stem, Word.femNomSentences.length)" outlined autocomplete="off" v-model="Word.femForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[1], Word.stem, Word.femGenSentences.length)" outlined autocomplete="off" v-model="Word.femForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[2], Word.stem, Word.femAccISentences.length)" outlined autocomplete="off"  v-model="Word.femForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[3], Word.stem, Word.femAccASentences.length)" outlined autocomplete="off"  v-model="Word.femForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[4], Word.stem, Word.femDatSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[5], Word.stem, Word.femInstSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[6], Word.stem, Word.femPrepSentences.length)" outlined autocomplete="off" v-model="Word.femForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Neuter</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[0], Word.stem, Word.neutNomSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[1], Word.stem, Word.neutGenSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[2], Word.stem, Word.neutAccISentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[3], Word.stem, Word.neutAccASentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[4], Word.stem, Word.neutDatSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[5], Word.stem, Word.neutInstSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[6], Word.stem, Word.neutPrepSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Plural</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[0], Word.stem, Word.pluNomSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[1], Word.stem, Word.pluGenSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[2], Word.stem, Word.pluAccISentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[3], Word.stem, Word.pluAccASentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[4], Word.stem, Word.pluDatSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[5], Word.stem, Word.pluInstSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[6], Word.stem, Word.pluPrepSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                  </v-card>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

          </v-card>
        </transition-group>

      </div>



<!-- Paginate -->
      <div class="incompleteWords" v-if="Words.length > 0 && searchedAdjectives.length == 0">

        <transition-group name="fade">

          <v-card style="border-color: #fff;box-shadow: none; text-align:center" v-for="Word in Words" :key="Word.id">

            <v-expansion-panels>
              <v-expansion-panel style="margin: 10px 0">
                <v-expansion-panel-header style="border: 5px solid #ffbe0b !important;">
                  <h3>{{Word.english}}</h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="pt-2">

                  <v-card style="border-color: #fff;box-shadow: none; text-align:center">
                    <v-row style="margin:0;padding:0">
                      <v-col tile cols="1.5" v-for="i in docItems" :key="i">
                        <v-chip label color="#8ecae6">{{i}}</v-chip>
                      </v-col>
                      <v-col cols="1.5">
                        <v-chip label color="#8ecae6">DEL. + SAVE</v-chip>
                      </v-col>
                    </v-row>
                  </v-card>

                  <v-card style="border-color: #fff;box-shadow: none; text-align:center">
                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined autocomplete="off" ref="adjEnglishRef" v-model="Word.english" class="ma-0 pa-0 text-english"></v-text-field></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"><v-text-field hide-details tile outlined :ref='"input_" + Word.id' v-model="Word.stem" @keyup="updateDeclensions(Word)" class="ma-0 pa-0 text-stem"></v-text-field></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1">
                        <v-checkbox
                          v-if="Word.stem != null && Word.stem.substring(Word.stem.length - 1, Word.stem.length) == 'н'"
                          v-model="Word.softStem"
                          :label="'Soft-Stem?'"
                          @change="updateDeclensions(Word)"
                          hide-details
                        ></v-checkbox>
                      </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col cols="1.5" class="d-inline-flex pa-0 ma-1">
                        <v-btn icon color="#e63946" :disabled="disableDeleteButton(Word)" @click="deleteWord(Word)">
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>

                        <v-btn icon color="#90be6d" :disabled="inputsDisabled" @click="updateWord(Word)">
                          <v-icon>
                            mdi-check-circle
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Masculine</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[0], Word.stem, Word.mascNomSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[1], Word.stem, Word.mascGenSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[2], Word.stem, Word.mascAccISentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[3], Word.stem, Word.mascAccASentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[4], Word.stem, Word.mascDatSentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[5], Word.stem, Word.mascInstSentences.length)" outlined autocomplete="off"  v-model="Word.mascForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.mascForms[6], Word.stem, Word.mascPrepSentences.length)" outlined autocomplete="off" v-model="Word.mascForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Feminine</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[0], Word.stem, Word.femNomSentences.length)" outlined autocomplete="off" v-model="Word.femForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[1], Word.stem, Word.femGenSentences.length)" outlined autocomplete="off" v-model="Word.femForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[2], Word.stem, Word.femAccISentences.length)" outlined autocomplete="off"  v-model="Word.femForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[3], Word.stem, Word.femAccASentences.length)" outlined autocomplete="off"  v-model="Word.femForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[4], Word.stem, Word.femDatSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[5], Word.stem, Word.femInstSentences.length)" outlined autocomplete="off"  v-model="Word.femForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.femForms[6], Word.stem, Word.femPrepSentences.length)" outlined autocomplete="off" v-model="Word.femForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Neuter</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[0], Word.stem, Word.neutNomSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[1], Word.stem, Word.neutGenSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[2], Word.stem, Word.neutAccISentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[3], Word.stem, Word.neutAccASentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[4], Word.stem, Word.neutDatSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[5], Word.stem, Word.neutInstSentences.length)" outlined autocomplete="off"  v-model="Word.neutForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.neutForms[6], Word.stem, Word.neutPrepSentences.length)" outlined autocomplete="off" v-model="Word.neutForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                    <v-row hide-details class="d-flex pa-0 ma-1">
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"></v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-chip large label class="chipWidth" color="#8ecae6">Plural</v-chip> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[0], Word.stem, Word.pluNomSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[0]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[1], Word.stem, Word.pluGenSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[1]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[2], Word.stem, Word.pluAccISentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[2]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[3], Word.stem, Word.pluAccASentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[3]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[4], Word.stem, Word.pluDatSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[4]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[5], Word.stem, Word.pluInstSentences.length)" outlined autocomplete="off"  v-model="Word.pluForms[5]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col tile cols="1.5" class="d-flex pa-0 ma-1"> <v-text-field hide-details tile :class="getColourComplete(Word.pluForms[6], Word.stem, Word.pluPrepSentences.length)" outlined autocomplete="off" v-model="Word.pluForms[6]" class="ma-0 pa-0"></v-text-field> </v-col>
                      <v-col cols="0.5" class="d-inline-flex pa-0 ma-1"> </v-col>
                    </v-row>

                  </v-card>

                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

          </v-card>
        </transition-group>

        <template>
          <div class="text-center">
            <v-pagination
              color="#ffb703"
              v-model="page"
              :length="numberOfPages"
              @input="updatePagination()">
            </v-pagination>
          </div>
        </template>

      </div>

    </div>
  </v-app>
</template>

<style media="screen">
  .v-application--wrap{
    min-height: 0;
  }

  h1{
    margin: 0 0 20px 0;
  }

  hr {
    border: 5px solid #383838;
    border-radius: 5px;
  }

  .viewWordsContent {
    width: calc(100vw - 40px);
    margin: auto;
    margin-top: 0;
    padding: 20px;
    text-align: left;
  }

  .v-text-field input {
    font-size: 1em;
    font-family: sans-serif;
  }

  .text-english {
    background-color: #ffb703;
    font-weight: bold;
  }

  .text-stem {
    background-color: #caf0f8;
    font-weight: bold;
  }

  .colour-white {
      background-color: #fff !important;
      padding: 0;
      margin: 0;
  }

  .colour-0 {
      background-color: #d8f3dc !important;
      padding: 0;
      margin: 0;
  }

  .colour-1 {
    background-color: #b7e4c7 !important;
    padding: 0;
    margin: 0;
  }

  .colour-2 {
    background-color: #95d5b2 !important;
    padding: 0;
    margin: 0;
  }

  .colour-3 {
    background-color: #74c69d !important;
    padding: 0;
    margin: 0;
  }

  .colour-masculine {
    background-color: #a2d2ff !important;
    padding: 0;
    margin: 0;
  }

  .colour-incomplete {
    background-color: #ffafcc !important;
    padding: 0;
    margin: 0;
  }

  .colour-neuter {
    background-color: #b5e48c !important;
    padding: 0;
    margin: 0;
  }

  .text-blue input {
      background-color: #blue !important;
  }

  .chipWidth {
    width: 100%;
  }


</style>









<script>
import {db} from '../firebase/db';
import firebase from 'firebase';

export default {
  title: "Adjectives",
  data() {
    return {
      signedIn: false,
      page: 1,
      pageLimit: 10,
      numberOfPages: 1,
      Words: [],
      WordsTotal: [],
      IncompleteWords: [],
      newItem: "",
      docItems: ['english', 'stem', 'nom', 'gen', 'acc I.', 'acc A.', 'dat', 'inst', 'prep'],
      genders: ['m', 'f', 'n'],
      endingStressed: ['yes', 'no'],
      quantities: ['s', 'p'],
      english: "",
      stem: "",
      componentKey: 0,
      searchedAdjectives: [],
      searchedResultTag: "results",
      adjectivesSearchBar: "",
      mascNom: "",
      mascGen: "",
      mascAccA: "",
      mascAccI: "",
      mascDat: "",
      mascInst: "",
      mascPrep: "",
      femNom: "",
      femGen: "",
      femAccA: "",
      femAccI: "",
      femDat: "",
      femInst: "",
      femPrep: "",
      neutNom: "",
      neutGen: "",
      neutAccA: "",
      neutAccI: "",
      neutDat: "",
      neutInst: "",
      neutPrep: "",
      pluNom: "",
      pluGen: "",
      pluAccA: "",
      pluAccI: "",
      pluDat: "",
      pluInst: "",
      pluPrep: "",
      adjectiveGenderField: "",
      adjectiveStressField: "",
      adjectiveQuantityField: "",
      inputsDisabled: false,
      softStem: false
    }
  },
  methods: {
    getColorClass(value) {
      var classes = ['colour-white', 'colour-0', 'colour-1', 'colour-2', 'colour-3'];

      if (value == 'm') {
        return 'colour-masculine';
      } else if (value == 'f') {
        return 'colour-feminine';
      } else if (value == 'n') {
        return 'colour-neuter';
      }
      if (value > 4) {
        return 'colour-3';
      } else {
        return classes[value];
      }
    },
    getColourComplete(value, stem, length) {

      var classToReturn = ""
      if (value == stem || stem == null) {
        classToReturn += 'colour-incomplete ';
      }

      var classes = ['colour-white', 'colour-0', 'colour-1', 'colour-2', 'colour-3'];

      if (length > 4) {
        classToReturn += 'colour-3';
      } else {
        classToReturn += classes[length];
      }

      return classToReturn;
    },
    disableDeleteButton(Word) {

      var types = ['masc', 'fem', 'neut', 'plu']
      var cases = ['Nom', 'Gen', 'AccA', 'AccI', 'Dat', 'Inst', 'Prep']

      var count = 0;

      for (var i = 0; i < types.length; i++) {
        for (var j = 0; j < cases.length; j++) {
          var array = types[i] + cases[j] + "Sentences";
          count += Word[array].length
        }
      }

      return ((count > 0) || this.inputsDisabled) ? true : false
    },

    toggleAllInputs() {
      this.inputsDisabled = !this.inputsDisabled;

      return this.inputsDisabled;
    },


    updateDeclensions(loc) {

      var lastCharacter = ""
      if (loc == "this") {
        lastCharacter = eval(loc + ".stem.substring(" + loc + ".stem.length - 1, " + loc + ".stem.length)" );
      } else {
        lastCharacter = loc.stem.substring(loc.stem.length - 1, loc.stem.length);
      }

      var locstemField = ""
      if (loc == 'this') {
        locstemField = eval(loc + ".stem" );
      } else {
        locstemField = loc.stem
      }



      var softStemChosen = false
      if (loc == 'this') {
        softStemChosen = this.softStem
      } else {
        softStemChosen = loc.softStem
      }


      var declensionEndings = []

      var mascEndings = ["", "", "", "", "", "", ""];
      var femEndings = ["", "", "", "", "", "", ""];
      var neutEndings = ["", "", "", "", "", "", ""];
      var pluEndings = ["", "", "", "", "", "", ""];

      if (   (lastCharacter == "г")
          || (lastCharacter == "к")
          || (lastCharacter == "х") ) {

            //nom, gen, accI, accA, dat, inst, prep
            mascEndings = ["", "ого", "ого", "", "ому", "им", "ом"];
            femEndings = ["ая", "ой", "ую", "ую", "ой", "ой", "ой"];
            neutEndings = ["ое", "ого", "ое", "ое", "ому", "им", "ом"];
            pluEndings = ["ие", "их", "ие", "их", "им", "ими", "их"];

      } else if (   (lastCharacter == "ж")
                 || (lastCharacter == "ш")
                 || (lastCharacter == "ч")
                 || (lastCharacter == "щ") ) {

                   mascEndings = ["", "", "", "", "", "им", ""];
                   femEndings = ["ая", "", "ую", "ую", "", "", ""];
                   neutEndings = ["", "", "", "", "", "им", ""];
                   pluEndings = ["ие", "их", "", "", "им", "ими", "их"];

      } else if (softStemChosen && (lastCharacter == "н")) {
        //possible soft stem -> need to check
        mascEndings = ["ий", "его", "его", "ий", "ему", "им", "ем"];
        femEndings = ["яя", "ей", "юю", "юю", "ей", "им", "ем"];
        neutEndings = ["ее", "его", "ее", "ее", "ему", "им", "ем"];
        pluEndings = ["ие", "их", "их", "ие", "им", "ими", "их"];


      } else if (locstemField != "") {
        //hard stem

        mascEndings = ["", "ого", "", "", "ому", "ым", "ом"];
        femEndings = ["ая", "ой", "ую", "ую", "ой", "ой", "ой"];
        neutEndings = ["ое", "ого", "ое", "ое", "ому", "ым", "ом"];
        pluEndings = ["ые", "ых", "ых", "ые", "ым", "ыми", "ых"];
      }


      var genders = ['masc', 'fem', 'neut', 'plu'];
      var cases = ['Nom', 'Gen', 'AccA', 'AccI', 'Dat', 'Inst', 'Prep'];

      declensionEndings.push(mascEndings);
      declensionEndings.push(femEndings);
      declensionEndings.push(neutEndings);
      declensionEndings.push(pluEndings);


      var field

      for (var i = 0; i < genders.length; i++) {
        for (var j = 0; j < cases.length; j++) {

          if (loc == "this") {
            field = genders[i] + cases[j];
            this[field] = locstemField + declensionEndings[i][j];
          } else {
            field = genders[i] + "Forms";

            loc[field][j] = locstemField + declensionEndings[i][j];
          }

        }
      }

      if (loc != "this") {
        this.componentKey += 1;

        this.$nextTick(() => {
          this.$refs["input_" + loc.id][0].focus()
        })
      }


    },



    async search() {

      await db.collection("Adjectives").where("incomplete", "==", false).where("english", "==", this.adjectivesSearchBar)
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {

              var word = doc.data();
              word.id = doc.id


              this.searchedAdjectives.push(word)

            })
          }
        })

        for (var i = this.searchedAdjectives.length - 1; i >= 0; i--) {
          if (this.searchedAdjectives[i].english != this.adjectivesSearchBar) {
              this.searchedAdjectives.splice(i, 1);
          }
        }


        this.searchedResultTag = (this.searchedAdjectives.length == 1) ? "result" : "results"


    },




    async updatePagination() {

      this.Words = []


      var totalLength = (this.WordsTotal.length % this.pageLimit == 1) ? this.WordsTotal.length + 1 : this.WordsTotal.length


      if ((this.WordsTotal.length % this.pageLimit != 0) && this.page == this.numberOfPages) {

        var remainder = this.WordsTotal.length % this.pageLimit
        this.Words = this.WordsTotal.slice(Math.max(this.WordsTotal.length - remainder, 0))


      } else if ((this.pageLimit * this.page) <= totalLength) {

                  if (this.WordsTotal[(this.pageLimit * (this.page - 1))] == "NULL") {

                    var indextemp = (this.pageLimit * (this.page - 1))

                    for (var i = 0; i < this.pageLimit; i++) {
                      await db.collection("Adjectives").orderBy("adjectiveID").startAt(indextemp).limit(1)
                        .get()
                        .then((querySnapshot) => {
                          if (!querySnapshot.empty) {
                            querySnapshot.forEach((doc) => {

                              var word = doc.data();
                              word.id = doc.id
                              this.WordsTotal[indextemp] = word

                            })
                          }
                        })

                        indextemp++
                    }

                  }

                  var arrayComponent1 = (this.pageLimit * (this.page - 1))
                  var counter1 = 0;

                  while (counter1 < this.pageLimit) {
                    if (this.WordsTotal[arrayComponent1 + counter1] == null) {
                      break;
                    } else {
                      this.Words.push(this.WordsTotal[arrayComponent1 + counter1])
                      counter1++
                    }
                  }

      } else {


        while (this.WordsTotal.length < ((this.page - 1) * this.pageLimit)) {
          this.WordsTotal.push("NULL")
        }


        var temp = ((this.page - 1) * this.pageLimit)

        await db.collection("Adjectives").orderBy("adjectiveID").startAt(temp).limit(this.pageLimit)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {

                var word = doc.data();

                word.id = doc.id
                this.WordsTotal.push(word)

              })
            }
          })



          var arrayComponent = ((this.page - 1) * this.pageLimit)
          var counter = 0;

          while (counter < this.pageLimit) {
            if (this.WordsTotal[arrayComponent + counter] == null) {
              break;
            }
            this.Words.push(this.WordsTotal[arrayComponent + counter])
            counter++
          }

      }


    },







    async addWord(){

      this.toggleAllInputs();

      // ADD SOME SORT OF CHECK TO ENSURE NO DUPLICATES

      var numberOfAdjectives = 0;

      var doc = await db.collection('Statistics').doc('STATISTICS').get();
      if (!doc.exists) {
        console.log('No such document!');
      } else {
        var stats = doc.data();
        numberOfAdjectives = stats.numberOfAdjectives;
        console.log("number of adjectives: " + numberOfAdjectives);
      }

      var caseFields = [this.mascNom, this.mascGen, this.mascAccI, this.mascAccA, this.mascDat, this.mascInst, this.mascPrep,
                        this.femNom, this.femGen, this.femAccI, this.femAccA, this.femDat, this.femInst, this.femPrep,
                        this.neutNom, this.mascGen, this.neutAccI, this.neutAccA, this.neutDat, this.neutInst, this.neutPrep,
                        this.pluNom, this.pluGen, this.pluAccI, this.pluAccA, this.pluDat, this.pluInst, this.pluPrep]

      var incomplete = false;
      for (var i = 0; i < caseFields.length; i++) {
        if (caseFields[i] == this.stem) {
          incomplete = true;
        }
      }

      if (this.english != "" && this.stem != ""
        && incomplete == false) {

        await db.collection("Adjectives").add({
          english: this.english,
          stem: this.stem,
          softStem: this.softStem,

          mascForms: [this.mascNom, this.mascGen, this.mascAccA, this.mascAccI, this.mascDat, this.mascInst, this.mascPrep],
          mascNomSentences: [],
          mascGenSentences: [],
          mascAccASentences: [],
          mascAccISentences: [],
          mascDatSentences: [],
          mascInstSentences: [],
          mascPrepSentences: [],

          femForms: [this.femNom, this.femGen, this.femAccA, this.femAccI, this.femDat, this.femInst, this.femPrep],
          femNomSentences: [],
          femGenSentences: [],
          femAccASentences: [],
          femAccISentences: [],
          femDatSentences: [],
          femInstSentences: [],
          femPrepSentences: [],

          neutForms: [this.neutNom, this.neutGen, this.neutAccA, this.neutAccI, this.neutDat, this.neutInst, this.neutPrep],
          neutNomSentences: [],
          neutGenSentences: [],
          neutAccASentences: [],
          neutAccISentences: [],
          neutDatSentences: [],
          neutInstSentences: [],
          neutPrepSentences: [],

          pluForms: [this.pluNom, this.pluGen, this.pluAccA, this.pluAccI, this.pluDat, this.pluInst, this.pluPrep],
          pluNomSentences: [],
          pluGenSentences: [],
          pluAccASentences: [],
          pluAccISentences: [],
          pluDatSentences: [],
          pluInstSentences: [],
          pluPrepSentences: [],

          incomplete: false,
          adjectiveID: numberOfAdjectives
        });

        numberOfAdjectives++;

        await db.collection('Statistics').doc('STATISTICS').update({
          numberOfAdjectives: numberOfAdjectives
        });

        this.english = "";
        this.stem = "";
        this.softStem = false,


        this.mascNom = "";
        this.mascGen = "";
        this.mascAccA = "";
        this.mascAccI = "";
        this.mascDat = "";
        this.mascInst = "";
        this.mascPrep = "";

        this.femNom = "";
        this.femGen = "";
        this.femAccA = "";
        this.femAccI = "";
        this.femDat = "";
        this.femInst = "";
        this.femPrep = "";

        this.neutNom = "";
        this.neutGen = "";
        this.neutAccA = "";
        this.neutAccI = "";
        this.neutDat = "";
        this.neutInst = "";
        this.neutPrep = "";

        this.pluNom = "";
        this.pluGen = "";
        this.pluAccA = "";
        this.pluAccI = "";
        this.pluDat = "";
        this.pluInst = "";
        this.pluPrep = "";


      } else {
        alert("complete all fields")
      }

      this.toggleAllInputs();

    },




    async updateWord(ref) {

      this.toggleAllInputs();

      var caseFields =
        [ref.mascForms[0], ref.mascForms[1], ref.mascForms[2], ref.mascForms[3], ref.mascForms[4], ref.mascForms[5], ref.mascForms[6],
        ref.femForms[0], ref.femForms[1], ref.femForms[2], ref.femForms[3], ref.femForms[4], ref.femForms[5], ref.femForms[6],
        ref.neutForms[0], ref.neutForms[1], ref.neutForms[2], ref.neutForms[3], ref.neutForms[4], ref.neutForms[5], ref.neutForms[6],
        ref.pluForms[0], ref.pluForms[1], ref.pluForms[2], ref.pluForms[3], ref.pluForms[4], ref.pluForms[5], ref.pluForms[6]]

      var incomplete = false;
      for (var i = 0; i < caseFields.length; i++) {
        if (caseFields[i] == ref.stem) {
          incomplete = true;
        }
      }

      console.log(caseFields);

      if (ref.english != "" && ref.stem != ""
        && incomplete == false) {

          console.log("filled in");

          console.log(ref.softStem);

          await db.collection("Adjectives").doc(ref.id).update({
            english: ref.english,
            stem: ref.stem,
            softStem: ref.softStem,
            mascForms: [ref.mascForms[0], ref.mascForms[1], ref.mascForms[2], ref.mascForms[3], ref.mascForms[4], ref.mascForms[5], ref.mascForms[6]],
            femForms: [ref.femForms[0], ref.femForms[1], ref.femForms[2], ref.femForms[3], ref.femForms[4], ref.femForms[5], ref.femForms[6]],
            neutForms: [ref.neutForms[0], ref.neutForms[1], ref.neutForms[2], ref.neutForms[3], ref.neutForms[4], ref.neutForms[5], ref.neutForms[6]],
            pluForms: [ref.pluForms[0], ref.pluForms[1], ref.pluForms[2], ref.pluForms[3], ref.pluForms[4], ref.pluForms[5], ref.pluForms[6]],
            incomplete: false
          });

          if (ref.adjectiveID == null) {
            console.log("WAS NULL");

            await db.collection("Adjectives").doc(ref.id).update({
              adjectiveID: this.stats.numberOfAdjectives,
            });

            this.stats.numberOfAdjectives++

            await db.collection("Statistics").doc("STATISTICS").update({
              numberOfAdjectives: this.stats.numberOfAdjectives,
            });

          }

          console.log("done");

      } else {
        alert("Did not update: need to complete all fields")
        console.log("Did not update: need to complete all fields");
      }

      this.toggleAllInputs();

    },





    async deleteWord(word) {

      this.toggleAllInputs();

      var stats = await db.collection('Statistics').doc('STATISTICS').get();
      stats = stats.data();
      var numberOfAdjectives = stats.numberOfAdjectives;

      numberOfAdjectives--;

      await db.collection("Adjectives").doc(word.id).delete();





      //+------------------
      //| To ensure no gaps in incremental id sequence, set sentence with 'last' id to the id of the sentence just deleted.
      //| (no need to do this if deleting the last added one)
      //+------------------
      if (word.adjectiveID != numberOfAdjectives) {
        console.log("NOT EQUAL");
        var adjIDtoChange;
        await db.collection("Adjectives").where("adjectiveID", "==", numberOfAdjectives)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              querySnapshot.forEach((doc) => {
                // console.log(wordID);
                adjIDtoChange = doc.id;
                })
              }
            })

        await db.collection('Adjectives').doc(adjIDtoChange).update({
          adjectiveID: word.adjectiveID
        })
      }







      await db.collection('Statistics').doc('STATISTICS').update({
        numberOfAdjectives: numberOfAdjectives
      });

      this.toggleAllInputs();

    },

    async checkIfAdmin() {
      var user = await db.collection("Users").doc(this.uid).get();
      user = user.data();

      if (!user.admin) {
        this.$router.push({ name: 'Index'})
      }
      // return user.admin;
    }



  },
  firestore: {
    // Words: db.collection("Adjectives").where("incomplete", "==", false),
    IncompleteWords: db.collection("Adjectives").where("incomplete", "==", true).limit(5)
  },
  async beforeMount() {


    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        this.uid = user.uid;
        this.signedIn = true;
        this.username = user.displayName;

        this.checkIfAdmin(user.uid);


      } else {
        // User is signed out
        // ...
        this.signedIn = false;
        this.$router.push({ name: 'Index'})
      }
    });



    this.stats = await db.collection("Statistics").doc("STATISTICS").get();
    this.stats = this.stats.data();

    this.numberOfPages = Math.ceil((this.stats.numberOfAdjectives) / this.pageLimit);

    await db.collection("Adjectives").orderBy("adjectiveID").startAt(0).limit(this.pageLimit)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {

            var word = doc.data();
            word.id = doc.id;
            this.Words.push(word)
            this.WordsTotal.push(word)
            console.log(word);

          })
        }
      })


    console.log("~~~~");
    console.log("~~~~");
    console.log(this.Words);
    console.log("~~~~");
    console.log("~~~~");



  },
  mounted(){

  }
}
</script>










<!--  -->
